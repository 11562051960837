<template>
    <v-card elevation="0">
        <s-crud
            :config="config"
            title="Peligros SST"
            :filter="filter"
            add
            edit
            remove
            ref="ScrudDangerSSTHazard"
            @save="save($event)"
            @rowSelected="rowSelectedDanger($event)"
            searchInput
        >
        <template v-slot="props">
            <v-container>
                <v-row >
                    <v-col cols="4" class="s-col-form">
                        <s-select-definition :def="1542" label="Peligros SST" v-model="props.item.DsdDanger"></s-select-definition>
                    </v-col>
                    <v-col cols="4" class="s-col-form">
                        <s-text v-model="props.item.DsdDescription" label="Descripción"></s-text>
                    </v-col>
                    <v-col cols="4" class="s-col-form">
                        <s-text v-model="props.item.DsdDamage" label="Lesiones o Daños"></s-text>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        </s-crud>
    </v-card>
</template>

<script>
import _sDangerSSTDetail from "../../../../services/HumanResource/HmnDangerSSTDetailService";

export default {
    components: {
    },
    props: {
        DgsID: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            filter: {
                DgsID: 0
            },
            selectedDanger: [],
            config: {
                service: _sDangerSSTDetail,
                model: {
                    DsdID: "ID",
                    DsdDangerName: "string",
                    DsdDescription: "string",
                    DsdDamage: "string",
                },
                headers: [
                    { text: "ID", value: "DsdID" },
                    { text: "Peligro", value: "DsdDangerName" },
                    { text: "Descripción", value: "DsdDescription" },
                    { text: "Lesiones o Daños", value: "DsdDamage" },
                ],
            },
        };
    },
    methods: {
        save(item) {

            if (this.DgsID == 0) {
                this.$fun.alert("Seleccione una área y cargo", "warning");
                return;
            }
            if(item.DsdDescription == null || item.DsdDescription == '') {
                this.$fun.alert("Ingrese la descripción del peligro", "warning");
                return;
            }
            
            item.DgsID = this.DgsID;
            item.save();
        },
        rowSelectedDanger(item) {
            this.selectedDanger = item;
        },
        Initialize() {
            this.filter = {DgsID: this.DgsID };
        },
    },
    watch:{
        DgsID(){
            this.Initialize();
        }
    },
    created() {
        this.filter = {DgsID: this.DgsID};
    },
    mounted(){
    }
};
</script>
  