<template>
    <v-card elevation="0">
        <s-crud
            :config="config"
            title="Recomendaciones de Peligros SST"
            :filter="filter"
            add
            edit
            remove
            ref="ScrudDangerSSTRecommendation"
            @save="save($event)"
            @rowSelected="rowSelectedItem($event)"
            searchInput
        >
        <template v-slot="props">
            <v-container>
                <v-row >
                    <v-col cols="12" class="s-col-form">
                        <s-textarea v-model="props.item.DsrDescription" label="Descripción"></s-textarea>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        </s-crud>
    </v-card>
</template>

<script>
import _sDangerSSTRecommendation from "../../../../services/HumanResource/HmnDangerSSTRecommendationService";

export default {
    components: {
    },
    props: {
        DgsID: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            filter: {
                DgsID: 0
            },
            selectedItem: [],
            config: {
                service: _sDangerSSTRecommendation,
                model: {
                    DsrID: "ID",
                    DsrDescription: "string",
                },
                headers: [
                    { text: "ID", value: "DsrID" },
                    { text: "Descripción", value: "DsrDescription" },
                ],
            },
        };
    },
    methods: {
        save(item) {

            if (this.DgsID == 0) {
                this.$fun.alert("Seleccione una área y cargo", "warning");
                return;
            }
            if(item.DsrDescription == null || item.DsrDescription == '') {
                this.$fun.alert("Ingrese la descripción de la recomendacion", "warning");
                return;
            }
            
            item.DgsID = this.DgsID;
            item.save();
        },
        rowSelectedItem(item) {
            this.selectedItem = item;
        },
        Initialize() {
            this.filter = {DgsID: this.DgsID };
        },
    },
    watch:{
        DgsID(){
            this.Initialize();
        }
    },
    created() {
        this.filter = {DgsID: this.DgsID};
    },
    mounted(){
    }
};
</script>
  