import Service from "../Service";
const resource = "HmnDangerSST/";

export default {

    save(obj, requestID) {
        return Service.post(resource + "saveDetail", obj, {
            params: { requestID: requestID }
        });
    },
    pagination(dtr, requestID) {
        return Service.post(resource + "paginationDetail", dtr, {
            params: { requestID: requestID },
        });
    },
};