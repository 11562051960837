import Service from "../Service";
const resource = "HmnDangerSST/";

export default {

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID }
        });
    },
    pagination(dtr, requestID) {
        return Service.post(resource + "pagination", dtr, {
            params: { requestID: requestID },
        });
    },
    list(parameters,requestID) {
        return Service.post(resource + "list", parameters, {
            params: { requestID: requestID },
        });
    },
    duplicate(obj, requestID) {
        return Service.post(resource + "duplicate", obj, {
            params: { requestID: requestID },
        });
    },
};