<template>
  <div>
    <s-crud
        :config="config"
        title="Peligros y Recomendaciones SST"
        :filter="filter"
        add
        edit
        remove
        ref="ScrudDangerSST"
        @save="save($event)"
        @rowSelected="rowSelected($event)"
        searchInput
    >
        <template v-slot:options>
          <v-tooltip bottom>
          <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" small elevation="0" @click="duplicate">
              <v-icon color="default" x-small>
                  fas fa-copy
              </v-icon>
              </v-btn>
          </template>
          <span>Duplicar</span>
          </v-tooltip>
        </template>
        <template v-slot="props">
            <v-container>
                <v-row >
                    <v-col cols="6" class="s-col-form">
                      <s-select-area
                          v-model="props.item.AreID"
                          :text="props.item.AreName"
                          label="Área"
                      />
                    </v-col>
                    <v-col cols="6" class="s-col-form">
                      <s-select-position
                          label="Cargo"
                          v-model="props.item.PstID"
                          :text="props.item.PstName"
                          style="margin-top: -2px"
                      ></s-select-position>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </s-crud>
    <br>
    <v-card>
      <v-tabs 
        v-model="tab" 
        centered 
        height="35"
      >
        <v-tab href="#tabPeligros"> 
          Peligros
        </v-tab>
        <v-tab href="#tabRecommendation"> 
          Recomendaciones
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :value="'tabPeligros'">
          <v-card class="pa-4">
            <v-row justify="center">
              <v-col md="12" lg="12" sm="12">
                <danger-sst-hazard ref="dangerSstHazard" :DgsID="selected" @reload="reload()"/>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>

        <v-tab-item :value="'tabRecommendation'">
          <v-card class="pa-4">
            <v-row justify="center">
              <v-col md="12" lg="12" sm="12">
                <danger-sst-recommendation ref="dangerSstRecommendation" :DgsID="selected" @reload="reload()"/>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-dialog
      v-model="OpenDialog"
			persistent
			width="500"
		>
			<s-toolbar
				label="Duplicar Registro"
				close
				@close="OpenDialog = false"
				color="#BAB6B5"
				dark
			></s-toolbar>
			<v-card>
				<v-container>
					<v-row style="height: 60px;">
            <v-col cols="6" class="s-col-form">
              <s-select-area
                  v-model="AreID"
                  :text="AreName"
                  label="Área"
              />
            </v-col>
            <v-col cols="6" class="s-col-form">
              <s-select-position
                  label="Cargo"
                  v-model="PstID"
                  :text="PstName"
                  style="margin-top: -2px"
              ></s-select-position>
            </v-col>
          </v-row>
          <v-row>
						<v-col>
							<v-btn
								color="primary"
								block
								@click="saveDuplicate()"
							>
								Guardar
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
  </div>
</template>
  
<script>
import _sDangerSST from "../../../../services/HumanResource/HmnDangerSSTService";
import dangerSstHazard from "./DangerSSTHazard.vue";
import dangerSstRecommendation from "./DangerSSTRecommendation.vue";

export default {
  components: {
    dangerSstHazard,
    dangerSstRecommendation
  },
  data() {
    return {
      filter: {},
      selected: null,
      tab: "tabPeligros",
      DgsID: 0,
      OpenDialog : false,
      AreID: null,
      AreName: null,
      PstID: null,
      PstName: null,
      config: {
        service: _sDangerSST,
        model: {
          DgsID: "ID",
          AreName: "string",
          PstName: "string",
        },
        headers: [
          { text: "ID", value: "DgsID" },
          { text: "Área", value: "AreName" },
          { text: "Cargo", value: "PstName" },
        ],
      },
    };
  },
  methods: {
    save(item) {
      item.save();
    },
    rowSelected(item) {
      if (item && Array.isArray(item) && item.length > 0 && item[0] && item[0].DgsID !== undefined) {
        this.selected = item[0].DgsID;
      } else {
        this.selected = null;
      }
    },
    duplicate() {
      if (this.selected) {
        this.OpenDialog = true;
      }
    },
    saveDuplicate() {
      console.log(this.AreID, this.PstID);
      
      if (!this.AreID || !this.PstID) { // Usar || en lugar de &&
        this.$fun.alert("Seleccione Área y Cargo", "warning");
        return;
      }

      const obj = {
        DgsID: this.selected,
        AreID: this.AreID,
        PstID: this.PstID,
        UsrCreateID: this.$fun.getUserID(),
      };

      this.$fun
        .alert("¿Está seguro de duplicar el registro seleccionado?", "question")
        .then((val) => {
          if (val.value) {
            _sDangerSST
              .duplicate(obj, this.$fun.getUserID())
              .then((r) => {
                this.$fun.alert("Registro duplicado correctamente", "success");
                this.reload();
                this.OpenDialog = false;
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        });
    },
    reload(){
      this.$refs.ScrudDangerSST.refresh();
    },
  },
};
</script>
  