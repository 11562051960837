import Service from "../Service";
const resource = "HmnDangerSST/";

export default {

    save(obj, requestID) {
        return Service.post(resource + "saveRecommendation", obj, {
            params: { requestID: requestID }
        });
    },
    pagination(dtr, requestID) {
        return Service.post(resource + "paginationRecommendation", dtr, {
            params: { requestID: requestID },
        });
    },
};